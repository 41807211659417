import styled from 'styled-components'
import { colors } from '.'
import { space } from 'styled-system'

export const Button = styled.button`
  cursor: pointer;
  color: ${ props => props.color ? props.color : "white"};
  background: ${ props => props.bg ? props.bg : colors.brandRed };
  ${colors};
  text-transform: uppercase;
  padding: ${ props => props.small ? `8px 22px` : `14px 32px`};
  font-size: ${ props => props.small ? `.75em` : `1em`};
  display: ${ props => props.inline ? `inline` : `block` };
  border: none;
  max-width: 200px;
  border-radius: 2px;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  letter-spacing: 0.08em;
  font-weight: lighter;
  ${space}
  transition: background .2s;
  &:hover {
    background: ${ props => props.hoverColor ? props.hoverColor : colors.darkRoyalBlue };
    transition: background .33s
  }
  &.disabled {
    background: ${ colors.bg.lightGray };
    color: ${colors.copyGrey};
  }
`