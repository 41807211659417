import styled from 'styled-components'
import * as ds from '.'
import { space } from 'styled-system'

export const StyledInput = styled.input`
  background: ${props => props.bg ? props.bg : "white"};
  color: #000;
  border-width: ${ props => props.borderWidth ? props.borderWidth : 0};
  border-bottom: ${ props => props.borderBottom ? props.borderBottom : 0};
  padding: 0.25em 0.5em;
  margin-right: -0.5em;
  font-size: 1em;
  width: 75%;
  ${space}
  &::-webkit-input-placeholder {
    color: ${ props => props.placeholderColor ? props.placeholderColor : ds.colors.typography.darkTertiary};
    font-size: ${ds.typeScale.t4}
  }
`
export const StyledSubmit = styled.input`
  padding: 0.5em 1em;
  vertical-align: top;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1;
  background: #9b0000;
  display: block;
  cursor: pointer;
  margin-top: 1.5em;
  border-radius: 2px;
  border-width: 0.5px;
  border-color: #9b0000;
  color: ${ ds.colors.softWhite };
`