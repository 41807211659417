// Style constants
export const colors = {
  royalBlue: '#448AFF',
  green: 'green',
  darkRoyalBlue: '#7D0E0E',
  dusk: '#333333',
  midnight: '#121212',
  headingGrey: 'rgba(0,0,0,0.7)',
  brandRed: '#990000',
  copyGrey: 'rgba(0,0,0,0.6)',
  darkGray: '#333',
  softWhite: 'rgba(255,255,255,0.8)',
  white: 'rgb(255,255,255)',
  // lightGray: '#f7f7f7',
  lightGray: 'rgba(255,255,255,0.6)',
  black: '#000',
  red: 'red',
  orange: 'orange',
  midnightGradient: 'radial-gradient(circle, rgba(46,46,46,1) 5%, rgba(18,18,18,1) 37%)',
  typography: {
    darkPrimary: '#505050',
    darkSecondary: 'rgba(0, 0, 0, 0.6)',
    darkTertiary: 'rgba(0, 0, 0, 0.5)',
    lightPrimary: 'rgb(255,255,255)',
    lightSecondary: 'rgba(255, 255, 255, 0.77)',
    lightTertiary: 'rgba(255, 255, 255, 0.5)',
  },
  bg: {
    lightGray: '#f7f7f7',
    darkBackground: '#191919',
    tomato: '#f14b31',
  },
}

export const shadows = {
  box: {
    offset: '0 4px 8px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)',
    medium: '0px 0px 20px rgba(0,0,0,0.1)',
  },
  drop: '6px 6px 0 0 #3c3b3b',
}

export const mq = {
  xsmall: '35em',
  small: '40em',
  medium: '50em',
  large: '65em',
  xlarge: '75em',
}
export const spacing = {
  s0 : '0',
  s1 : '.25rem',
  s2 : '.5rem',
  s3 : '1rem',
  s4 : '2rem',
  s5 : '4rem',
  s6 : '8rem',
  s7 : '16rem',
  s8 : '32rem',
}
export const space = Object.values(spacing)
export const lineHeight = {
  lh1 : 0.7,
  lh2 : 1,
  lh3 : 1.2,
  lh4 : 1.414,
  lh5 : 1.999,
}
export const lineHeights = Object.values(lineHeight)
export const typeScale = {
  t1 : '0.354rem',
  t2 : '0.5rem',
  t3 : '0.707rem',
  t4 : '1rem',
  t5 : '1.414rem',
  t6 : '1.999rem',
  t7 : '2.827rem',
  t8 : '3.998rem',
}
export const fontSizes = Object.values(typeScale)
export const grid = {
  boxedWidth: '1024px',
}


export const sansSerif = 
`system-ui,
-apple-system,
BlinkMacSystemFont,
"Helvetica Neue",
Helvetica,
"Segoe UI",
Roboto,
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol";`

export const typography = {
  heading: `'overpass', ${sansSerif}`,
  p: `'overpass', ${sansSerif}`,
}

export const brandColors = {
  lightBrown: "#996845",
  mediumBrown: "#80573A",
  darkBrown: "#664933",
  lightGold: "#C4A462",
  mediumGold: "#B39151",
  darkGold: "#8C713F",
  lightGreen: "#9CAD68",
  mediumGreen: "#88995C",
  darkGreen: "#5A663D",
  lightBlue: "#689ABD",
  mediumBlue: "#5B87A6",
  darkBlue: "#3F5D73",
  lightRed: "#BF4C4C",
  mediumRed: "#A63A3A",
  darkRed: "#8C3838",
  typography: {
    black: "#000",
    dark: "#202020",
    darkGrey: "#616161",
    mediumGrey: "#808080",
    lightGrey: "#BEBEBE",
    light: "#E0E0E0",
    white: "#FFF",
  },
  bg: {
    black: "#000",
    dark: "#202020",
    light: "#E0E0E0",
    white: "#FFF",
  },
}