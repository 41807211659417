import styled from 'styled-components'
import {
  space,
  color,
  textAlign,
  position,
  display,
  flexDirection,
  justifyContent,
  justifySelf,
  alignItems,
  order,
} from 'styled-system'
import * as ds from '.'

export const Column = styled.aside`
  grid-column: span 12;
  -ms-grid-column-span: 12;
  @media screen and (min-width: ${ ds.mq.small}) {
    grid-column: span ${ props => props.span};
    -ms-grid-column-span: ${ props => props.span};
    order: ${ props => props.order};
    -ms-order: ${ props => props.order};
  }
  ${
  props => props.vc
    ? `
      display: flex;
      flex-direction: column;
      justify-content: center;
    `
    : null
  }
  ${textAlign};
  ${order};
  ${position};
  ${display};
  ${space};
  ${alignItems};
  ${justifyContent};
  ${justifySelf};
`
export const StyledColumn = styled(Column)`
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  padding-left: 1em;
  padding-bottom: 1em
`

export const CenteredColumn = styled(Column)`
  text-align: center;
  border-radius: 0px;
  transform: rotate(4deg) skew(4deg);
  justify-content: center;
  background-color: ${ds.colors.dusk};
  padding-left: ${ds.spacing.s4};
  padding-right: ${ds.spacing.s4};
  padding-top: ${ds.spacing.s4};
  padding-bottom: ${ds.spacing.s4};
  display: flex;
  ${flexDirection};
  ${color};
`