import styled from 'styled-components'
import * as ds from '../uiLibrary'
import {
  width,
  space,
  display,
  position,
  maxWidth,
  textAlign,
  boxShadow,
  background,
  alignItems,
  borderLeft,
  borderRadius,
  flexDirection,
} from 'styled-system'

export const Div = styled.div`
  ${space};
  ${display};
  ${position};
  ${maxWidth};
  ${textAlign};
  ${boxShadow};
  ${background};
  ${alignItems};
  ${borderRadius};
  ${flexDirection};
`

export const StyledDiv = styled.div`
  border-left-color: ${props => props.bc ? props.bc : ds.brandColors.darkGreen};
  padding-left: ${ds.spacing.s1};
  padding-right: ${ds.spacing.s1};
  transform: rotate(-4deg) skew(-4deg);
  ${width};
  ${borderLeft};

  @media screen and (min-width: ${ds.mq.medium}) {
    border-left-color: ${props => props.bc ? props.bc : ds.brandColors.darkGreen};
    border-left-width: ${props => props.index !== 0 ? '1px' : '0px'};
    padding-left: ${ds.spacing.s2};
    padding-right: ${ds.spacing.s2};
  }

  @media screen and (min-width: ${ds.mq.large}) {
    border-left-color: ${props => props.bc ? props.bc : ds.brandColors.darkGreen};
    border-left-width: ${props => props.index !== 0 ? '1px' : '0px'};
    padding-left: ${ds.spacing.s3};
    padding-right: ${ds.spacing.s3};
  }
`

export const StyledHeaderCtaDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};
`