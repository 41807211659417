import styled from 'styled-components'
import {
  color,
  justifyContent,
  space,
  borders,
  position,
  background,
  textAlign,
  backgroundImage,
  height,
} from 'styled-system'
import * as ds from '.'

export const BGLightGray = styled.div`
  background: ${ ds.colors.lightGray};
  ${ props => props.border ?
    `border-bottom: 1px solid;
    border-bottom-color: rgb(234, 234, 234);
    border-top: 1px solid;
    border-top-color: rgb(234, 234, 234);` :
    null
  } 
`
export const Background = styled.section`
  background-size: cover;
  background-position: 50% 50%;
  ${space};
  ${color};
  ${height};
  ${borders};
  ${position};
  ${textAlign};
  ${background};
  ${backgroundImage};
`
export const Row = styled.div`
${space}
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

`
export const PaddedTopRow = styled(Row)`
  padding: 6em 2em 0 2em;
  max-width: 1024px;
  margin: auto;
  @media screen and (min-width: ${ ds.mq.medium}) {
    padding: 6.6em 6em 0 6em;
  }
`
export const PaddedBottomRow = styled(Row)`
  padding: 0 2em 6em 2em;
  max-width: 1024px;
  margin: auto;
  @media screen and (min-width: ${ ds.mq.medium}) {
    padding: 0 2em 6.6em 2em;
  }
`
export const PaddedRow = styled(Row)`
  padding: 6em 2em;
  max-width: 1024px;
  margin: auto;
  @media screen and (min-width: ${ ds.mq.medium}) {
    padding: 6em
  }
`
export const FixedWidthContainer = styled.div`
${space};
${color};
${height};
${justifyContent};
  max-width: 1200px;
  margin: auto
`

export const YCenteredDiv = styled.div`
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
`
export const XYCenteredDiv = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%
`
// export const YCenteredDivNS = styled(YCenteredDiv)`
//   top: 20%;
//   @media screen and (min-width: ${ ds.mq.small }) {
//     top: 50%
//   }
// `
// export const BackgroundDarken = styled.div`
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   background: rgba(0,0,0,0.7);
// `
// export const BackgroundDarkenBlur = styled(BackgroundDarken)`
// `
// export const HideOnMobile = styled.div`
//   display: none;
//   @media screen and (min-width: ${ ds.mq.small }) {
//     display: block
//   }
// `