import React from 'react';
import styled from "styled-components";
import * as ds from "./";
import {
  space,
  flex,
  justifyContent,
  textAlign,
  color,
  borderTop,
  width,
  borderBottom,
  borderLeft,
  borderRight,
} from "styled-system";

export const StyledTable = styled.table`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: black;
  align-items: stretch;
  font-family: Overpass, ${ds.sansSerif};
  ${space};
`

export const TableHeadRow = styled.thead`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  `

export const TableHead = styled.th`
  display: flex;
  font-size: ${ds.typeScale.t5};
  ${width};
  ${space};
  ${flex};
  ${color};
`

export const BodyHead = styled.td`
  display: flex;
  font-size: ${ds.typeScale.t4};
  ${space};
  ${flex};
`

export const TableRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${color};
  ${borderTop};
  ${borderBottom};
  ${borderLeft};
  ${borderRight};
`

export const TableData = styled.td`
  display: flex;
  color: white;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: ${ds.spacing.s2};
  font-size: ${ds.typeScale.t4};
  ${flex};
  ${justifyContent};
  ${textAlign};
`

const StyledTableIcon = styled.img`
  max-height: 20px;
`

export const TableIcon = props =>
  <StyledTableIcon src={props.src} alt={"TONIT Icons"} />