import styled from 'styled-components'
import { space, color, display, alignItems, flexDirection, justifyContent } from 'styled-system'
import { Row } from '.'
import * as ds from '.'

export const FixedWidthRow = styled(Row)`
  padding: ${ds.spacing.s4};
  ${space};
  ${display};
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  ${color};

  @media screen and (min-width: ${ds.mq.medium}) {
    padding: ${ds.spacing.s5} ${ds.spacing.s4};
    ${space};
    ${display};
    ${alignItems};
    ${flexDirection};
    ${justifyContent};
  }
  @media screen and (min-width: ${ds.mq.large}) {
    padding: ${ds.spacing.s6} 0;
    margin: ${ds.spacing.s7} 0;
    max-width: ${ds.grid.boxedWidth};
    margin: 0 auto;
    ${space};
    ${display};
    ${alignItems};
    ${flexDirection};
    ${justifyContent};
  }
`