import styled from 'styled-components'
import * as ds from '../uiLibrary'
import { mq } from '.'
import { display, width, space, textAlign } from 'styled-system'

export const Card = styled.div`
  ${display}
  width: ${props => props.width ? props.width : "80%"};
  height: ${props => props.height};
  background-color: ${props => props.bg ? props.bg : "white"};
  border-radius: 3px;
  box-shadow: ${ds.shadows.box.offset};
  margin: 16px auto;
  padding: 32px;
  @media screen and (min-width: ${ mq.medium}) {
    width: ${props => props.width ? props.width : "460px"};
  }
`

export const ClickableCard = styled(Card)`
cursor: pointer;
transition: 0.3s all;
  &:hover {
    transition: 0.3s all;
    transform: scale(0.99);
  }
  ${ width };
  ${ space };
  ${ textAlign };
`