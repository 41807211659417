import styled from 'styled-components'
import { space, maxWidth, display, maxHeight } from 'styled-system'
import * as ds from '../uiLibrary'

export const AbsoluteCenteredImg = styled.img`
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`
export const CenteredImg = styled.img`
  margin: 0 auto;
  ${maxWidth};
  ${maxHeight};
  ${display};
  ${props => props.transform ? `transform: ${props.transform}` : null};
  @media screen and (min-width: ${ds.mq.medium}){
    ${maxWidth};
    ${maxHeight};
    ${display};
  }
  @media screen and (min-width: ${ds.mq.large}){
    ${maxWidth};
    ${maxHeight};
    ${display};
  }
`
export const RetinaScaledImage = styled.img`
  ${space};
  max-width: 50%;
`

export const FaqImage = styled.img`
  max-width: 80%;
  padding-top: 5px;
  margin: 0 auto;
  &:hover {
    transition: .333s all;
    transform: scale(1.1);
  }
`

export const CenteredImage = styled.img`
  margin: 0 auto;
  max-width: ${ props => props.mw ? props.mw : "40%"};
  padding-bottom: 2rem;
  ${space};
`

export const RoundImage = styled.img`
  z-index: -1;
  max-width: 450px;
  border-radius: 50%;
`