import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'

const AnimatronicVerticulator = styled.div`
  overflow: hidden;
  transition: max-height 0.9s ease-in-out;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  max-height: ${ props => props.isOpen ? `auto` : `90px`};
  `
const FlexyMuhFuhr = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
`
const DropdownTitle = styled.h4`
  color: ${ ds.colors.black};
	font-weight: 400;
  font-size: ${ props => props.fontSize ? props.fontSize : ds.fontSizes[4]};
  fontFamily: ${`'Rubik', ${ds.sansSerif}`};
`
export class Dropdown extends React.Component {
  state = {
    isOpen: false,
  }

  render() {
    const { isOpen } = this.state
    return (
      <AnimatronicVerticulator isOpen={isOpen}>
        <FlexyMuhFuhr onClick={this._expandPosting.bind(this)}>
          <DropdownTitle fontSize={this.props.fontSize}>{this.props.position}</DropdownTitle>
          <ds.H4 color={'black'}>▾</ds.H4>
        </FlexyMuhFuhr>
        {this.props.render()}
      </AnimatronicVerticulator>
    )
  }
  _expandPosting = e => {
    this.setState({ isOpen: !this.state.isOpen })
  }
}