import React from 'react'
import styled from 'styled-components'
import {
	space,
	color,
	fontWeight,
	textAlign,
	fontSize,
	fontFamily,
	display,
	width,
	flex,
	alignSelf,
	lineHeight,
	letterSpacing,
} from 'styled-system'
import * as ds from '.'
import * as theme from '../uiLibrary/constants'

export const PStyles = styled.p`
	font-size: ${ props => props.fontSize ? props.fontSize : ds.typeScale.t4};
	color: ${ props => props.color ? props.color : ds.colors.lightGray};
	font-weight: 300;
	line-height: ${ props => props.lineHeight ? props.lineHeight : ds.lineHeight[4]};
	${textAlign};
	${fontSize};
	${fontFamily};
	${space};
`
export const P = props => <PStyles fontSize={[ds.typeScale.t4, ds.typeScale.t4, ds.typeScale.t5]} fontFamily={`'Roboto', ${ds.sansSerif}`} {...props}/>

export const LeadText = styled.p`
	font-weight: ${props => props.fontWeight ? props.fontWeight : "300"};
	font-size: ${props => props.fontSize ? props.fontSize : ds.typeScale.t5};
	line-height: ${ props => props.lineHeight ? props.lineHeight : ds.lineHeight[4]};
	color: ${ props => props.color ? props.color : ds.colors.lightGray};
	text-align: justify;
`

const TonitHeading = Component => props =>
	<Component
		lineHeight={ds.lineHeight.lh3}
		fontWeight={400}
		fontFamily={`'Rubik', ${ds.sansSerif}`}
		{...props}
	/>

export const HeadlineStyles = styled.h1`
	margin-top: ${ds.spacing.s3};
	margin-bottom: ${ds.spacing.s3};
	line-height: ${ds.lineHeight.lh3};
	font-size: ${ ds.typeScale.t6};
	color: ${ props => props.color ? props.color : ds.colors.white};
	@media screen and (min-width: ${ ds.mq.large}){
		font-size: ${ ds.typeScale.t7};
		${fontSize};
		${fontWeight};
		${space};
		${color};
		${textAlign}
		${fontFamily};
	}
	${fontSize};
	${fontWeight};
	${space};
	${color};
	${textAlign}
	${fontFamily};
`
export const Headline = TonitHeading(HeadlineStyles)

export const H2Styles = styled.h2`
	font-size: ${ds.typeScale.t5};
	color: ${ props => props.color ? props.color : ds.colors.white};
	@media screen and (min-width: ${ ds.mq.large}){
		font-size: ${ds.typeScale.t6};
	}
	${fontWeight};
	${fontSize};
	${display};
	${textAlign};
	${space};
	${color};
	${fontFamily};
`
export const H2 = TonitHeading(H2Styles)

export const H3Styles = styled.h3`
	color: ${ ds.colors.white};
	font-size: ${ ds.fontSizes[5]};
	@media screen and (min-width: ${ ds.mq.large}){
		font-size: ${ds.typeScale.t6};
	}
	${fontWeight};
	${display};
	${fontSize};
	${textAlign};
	${space};
	${color};
	${fontFamily};
`
export const H3 = TonitHeading(H3Styles)

export const H4Styles = styled.h4`
	color: ${ ds.colors.white};
	font-size: ${ ds.fontSizes[4]};
	font-weight: 400;
	${fontWeight};
	${fontSize};
	${textAlign};
	${space};
	${color};
	${fontFamily};
`
export const H4 = TonitHeading(H4Styles)

export const Eyebrow = styled.h3`
	font-weight: 300;
	color: ${ props => props.color ? props.color : ds.colors.lightGray};
	${space};
	${display};
	${fontSize};
	${fontFamily};
`

// not used
export const Lead = styled.p`
	max-width: 30em;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.4;
	margin: 1em auto;
	letter-spacing: 0.1em;
	color: rgba(0,0,0,0.7);
	text-transform: uppercase;
	@media screen and (min-width: ${ ds.mq.small}){
		font-size: 1.5em
	}
	${fontFamily};
`
export const BoxedCTAStyles = styled.h3`
	margin-top: 0;
	background-color: white;
	display: inline-block;
	letter-spacing: 0.05em;
	padding: ${ ds.spacing.s2} ${ds.spacing.s3};
	font-size: ${ ds.typeScale.t5};
	${fontWeight};
	${fontSize};
	${display};
	${textAlign};
	${space};
	${color};
	${fontFamily};
`
export const BoxedCTA = TonitHeading(BoxedCTAStyles);

export const Label = styled.label`
	text-transform: uppercase;
	letter-spacing: 0em;
	font-size: 0.666em;
	font-weight: 600;
	color: ${ds.colors.black};
	${space};
	font-family: ${ds.typography.p};
`
export const Supertitle = styled.h3`
	font-weight: 700;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	max-width: 30em;
`
export const brandRed = styled.span`
	color: #990000;
`

// *** New Typography set: *** //

const TonitStyles = Component => props =>
	<Component
		space={space}
		width={width}
		color={color}
		display={display}
		alignSelf={alignSelf}
		fontSize={fontSize}
		textAlign={textAlign}
		lineHeight={lineHeight}
		letterSpacing={letterSpacing}
		fontFamily={fontFamily}
		fontWeight={fontWeight}
		flex={flex}
		{...props}
	/>

const Typography = styled.div`
  ${space}
  ${width}
  ${color}
  ${flex}
  ${alignSelf}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
`

const Heading = styled(Typography)`
	margin-top: ${ds.spacing.s3};
	margin-bottom: ${ds.spacing.s3};
	font-size: ${theme.typeScale.t6};
	line-height: ${ds.lineHeight.lh3};
	font-weight: ${400};
	font-family: ${ds.typography.heading};

`

const Paragraph = styled.p`
	line-height: ${props => props.lineHeight ? props.lineHeight : ds.lineHeight[4]};
	text-align: justify;
	font-family: ${ds.typography.p};
`

// HEADLINE used on hero images and for headings on pages
// would like a way for all of these property variables 
// (display, space, textAlign, etc.) to be transfered to each
// component extension -so they can be accessed as props-
// preferrably without adding them manually to each component

const HeadlineDarkStyles = styled(Heading)`
	color: ${ds.colors.brandRed};
	${fontSize};
`

// Unable to use props.color ternary? - defaults to black
const HeadlineLightStyles = styled(Heading)`
	color: ${theme.colors.white};
	${fontSize};
`
export const HeadlineLight = TonitStyles(HeadlineLightStyles)
export const HeadlineDark = TonitStyles(HeadlineDarkStyles)


// H1 Used in hero under headline
const H1 = styled(Heading)`
	font-size: ${theme.typeScale.t4};
	@media screen and (min-width: ${ds.mq.medium}) {
		font-size: ${theme.typeScale.t5};
	}
	@media screen and (min-width: ${ ds.mq.large}){
		font-size: ${theme.typeScale.t6};
	}
`

const H1DarkStyles = styled(H1)`
	color: ${ theme.colors.headingGrey};
	${fontWeight};
`
const H1LightStyles = styled(H1)`
	color: ${props => props.color ? props.color : theme.colors.softWhite};
	${fontWeight};
`
export const H1Dark = TonitStyles(H1DarkStyles);
export const H1Light = TonitStyles(H1LightStyles);


// H2 Used in the body of a page as a header
// const H2a = styled(Heading)

// H2a.defaultProps = {
// 	is: 'h2',
// 	fontSize: [6, 5]
// }

// const H2DarkStyles = styled(Heading)`
// 	color: ${ props => props.color ? props.color : ds.colors.headingGrey};
// `
// const H2LightStyles = styled(Heading)`
// 	color: ${ props => props.color ? props.color : ds.colors.softWhite};
// `
// export const H2Dark = TonitHeading(H2DarkStyles);
// export const H2Light = TonitHeading(H2LightStyles);

const PBase = styled(Paragraph)`
	font-weight: 400;
	font-size: ${theme.typeScale.t4};
	${textAlign};
`
Paragraph.defaultProps = {
	is: 'p',
	fontSize: 6,
}

const PLightStyles = styled(PBase)`
	color: ${theme.colors.typography.lightSecondary};
`
const PDarkStyles = styled(PBase)`
	color: ${theme.colors.black};
`
export const PLight = TonitStyles(PLightStyles);
export const PDark = TonitStyles(PDarkStyles);

export const AgreementsBody = styled(P)`
  color: ${ds.colors.headingGrey};
  text-align: justify;
  font-family: ${ds.typography.p};
  margin-vertical: 10;
`
export const AgreementsTitle = styled(H1Dark)`
  margin-top: 10;
`
export const AgreementsHeader = styled(H2)`
  margin-top: 10;
  color: ${ds.colors.copyGrey};
`
export const AgreementsSubheader = styled(H4)`
  margin-top: 10;
  color: ${ds.colors.darkGray};
`