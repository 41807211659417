import styled from 'styled-components'
import { colors } from '.'

export const BlueText = styled.span`
  color: ${ colors.royalBlue }
`

export const GreenText = styled.span`
  color: ${ colors.green }
`

export const RedText = styled.span`
  color: ${ colors.red }
`

export const OrangeText = styled.span`
  color: ${ colors.orange }
`

export const SoftWhiteText = styled.span`
 color: ${ colors.softWhite }
`

export const WhiteText = styled.span`
 color: ${ colors.white }
`