import styled from 'styled-components'
import {
  space,
  alignItems,
  justifyContent,
} from 'styled-system'

export const Flex = styled.div`
  display: flex;
  ${justifyContent};
  ${alignItems};
  ${space};
`
export const Box = styled.div`
  align-self: ${ props => props.as ? props.as : `auto`};
  ${space};
`
